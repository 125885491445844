.container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	padding-top: 150px;
}

.wrapper {
	max-width: 420px;
}

@media (max-width: 767px) {
	.container {
		margin: 0 15px 0;
		padding: 0;
	}
}
