.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading__circle {
    display: inline-block;
}

.loading__circle--sm {
    width: 1em;
    height: 1em;
}

.loading__circle--md {
    width: 2em;
    height: 2em;
}

.loading__circle--lg {
    width: 3em;
    height: 3em;
}

.loading__circle__svg {
    animation: circle-animation 700ms linear infinite;
}

.loading__circle__svg__circle {
    stroke-width: 8;
    stroke-dasharray: 80, 150;
    stroke-dashoffset: 0;
}

@keyframes circle-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
