.container {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 20px;
}

.links {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    gap: 10px;
}

.center {
    text-align: center;
}
