.btn-link {
    background: none;
    border: none;
    padding: 0;
    font-size: 1.6rem;
}

.btn-link:hover {
    cursor: pointer;
}
