body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading {
  text-align: center;
}

.flex-row--vertically-centred {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ovo-new-header {
  background-image: url("images/green-square.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 230px;
  overflow: hidden;
  background-position: 0 0;
  position: absolute;
  width: 100%;
}

@media (max-width: 767px) {
  .ovo-new-header {
    height:230px;
    background-image: none;
    background-color: #0d8426;
    width: 100vw;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 0 100%;
    transform: skewY(-5deg)
  }

  .container {
    margin-top: 65px;
    position: relative;
  }

  .heading {
    color: white !important;
  }
}

@media (min-width: 768px) and (max-width:991px),(min-width:992px) and (max-width:1199px) {
  .ovo-new-header {
    background-size: contain;
    background-repeat: no-repeat;
    height: 230px;
    overflow: hidden;
    background-position: 0 0
  }
}

.ovo-new-logo {
  background-image: url("images/logo.svg");
  background-size: 150px;
  background-repeat: no-repeat;
  height: 210px;
  overflow: hidden;
  background-position: top 50px left 50px
}

@media (max-width: 767px) {
  .ovo-new-logo {
    height:80px;
    background-image: url("images/green-logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top 36px left 50px;
    box-shadow: 0 2px 8px 0 rgba(12,0,51,.1);
    transform: skewY(5deg);
    z-index: 1;
    background-color: #fff
  }
}

@media (min-width: 768px) and (max-width:991px),(min-width:992px) and (max-width:1199px) {
  .ovo-new-logo {
    background-size: 150px;
    background-repeat: no-repeat;
    height: 210px;
    overflow: hidden;
    background-position: top 50px left 50px
  }
}

.ovoBranding {
  background-image: url("images/green-half-circle.png"),url("images/green-circle.png");
  background-size: 15%,10%;
  background-repeat: no-repeat,no-repeat;
  background-position: 100% 100%,bottom 200px right 130px;
  background-color: #f9f9f9;
  min-height: 100vh;
  height: 100%;
  width: 100%
}

@media (max-width: 767px) {
  .ovoBranding {
    background-image:none
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .ovoBranding {
    background-size: 13%,8%;
    background-repeat: no-repeat,no-repeat;
    background-position: 100% 100%,bottom 100px right 100px
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .ovoBranding {
    background-size: 15%,10%;
    background-repeat: no-repeat,no-repeat;
    background-position: 100% 100%,bottom 200px right 130px
  }
}

